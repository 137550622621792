function loadDataResCards() {
    const rgrCardsContent = [
        {
            "cardClassName": "card",
            "contentClassName": "card-content data-resource-card data-resource-card-cursor",
            "link": "/request/data/rgr/loggedin",
            "content": "<img src ='/assets/images/rgr_verily_logos.jpg' alt='RGR-VPB logo'>"
        }
    ]
    const gwtgCardsContent = [

        {
            "cardClassName": "card",
            "contentClassName": "card-content data-resource-card data-resource-card-cursor",
            "link": "/request/data/cvd/loggedin",
            "content": "<img style='margin-bottom:8rem; padding:0 3%; transform: translate(0, 50%)' src ='/assets/documentation/images/AHA_COVID-19_LOGO_CMYK.jpg' alt='COVID19 GWTG logo'>"
        },
        {
            "cardClassName": "card",
            "contentClassName": "card-content data-resource-card inactive",
            "content": "<img src='https://www.heart.org/-/media/images/professional/quality-improvement/qi-program-logos/get-with-the-guidelines-hf-program-logo-box.jpg?h=293&la=en&mw=440&w=440&hash=94B81A9BC6A24473C17278CA6431F5ADD442040C' alt='GWTG HF logo'>",
            "message": "<div class='ribbon-wrapper-green'><span class=\"ribbon-green\">Coming soon</span></div>"
        },
        {
            "cardClassName": "card",
            "contentClassName": "card-content data-resource-card inactive",
            "content": "<img src ='https://www.heart.org/-/media/images/professional/quality-improvement/qi-program-logos/get-with-the-guidelines-resus-program-logo-box.jpg?h=293&la=en&mw=440&w=440&hash=6DF67594808E49932F658009374A881F048886F5' alt='GWTG Resus logo'>",
            "message": "<div class='ribbon-wrapper-green'><span class=\"ribbon-green\">Coming soon</span></div>"
        },
        {
            "cardClassName": "card",
            "contentClassName": "card-content data-resource-card inactive",
            "content": "<img src='https://www.heart.org/-/media/images/professional/quality-improvement/qi-program-logos/get-with-the-guidelines-cad-program-logo-box.jpg?h=293&la=en&mw=440&w=440&hash=DD760660E6E6BAD6C29E1B0527062DFF4E7284ED' alt='GWTG CAD logo'>",
            "message": "<div class='ribbon-wrapper-green'><span class=\"ribbon-green\">Coming soon</span></div>"
        }
    ];

    const gwtgDiv = document.getElementById("gwtgCards");
    const gwtgCardRow = createRowDiv("cards-section");
    createCards(gwtgCardsContent, gwtgCardRow);
    gwtgDiv.appendChild(gwtgCardRow);
    const rgrDiv = document.getElementById("rgrCards");
    const rgrCardRow = createRowDiv("cards-section");
    createCards(rgrCardsContent, rgrCardRow);
    rgrDiv.appendChild(rgrCardRow);
}
