function loadCvdMenu() {
const quickLinkContent= [
                {
                    "btntype": "link",
                    "className": "btn secondary-btn",
                    "label": "Explore COVID19 Data",
                    "link": "/assets/downloads/cvd/search-data-documentation.html"
                },
                {
                    "btntype": "dropdown",
                    "className": "btn secondary-btn",
                    "label": "COVID19 Data Usage",
                    "menu": [
                        {
                            "label": "<img src='https://www.r-project.org/logo/Rlogo.png' alt='drawing' style='width:17%; vertical-align:middle; padding-right:5px; padding-bottom: 5px;'>  R",
                            "link": "/request/data/cvd/data-usage-r/loggedin",
                            "target": "_self"
                        },
                        {
                            "label": "<img src='https://www.python.org/static/opengraph-icon-200x200.png' alt='drawing' style='width:20%; vertical-align:middle; padding-right:5px; padding-bottom: 5px;'>Python",
                            "link": "/request/data/cvd/data-usage-python/loggedin",
                            "target": "_self"
                        },
                        {
                            "label": "<img src='https://www.automateexcel.com/excel/wp-content/uploads/2018/04/icon-shortcuts-sas.png' alt='drawing' style='width:15%; vertical-align:middle; padding-right:5px; padding-bottom: 5px;'> SAS",
                            "link": "/request/data/cvd/data-usage-sas/loggedin",
                            "target": "_self"
                        }
                    ]
                },
                {
                    "btntype": "link",
                    "className": "btn secondary-btn",
                    "label": "Upload Files to PMP",
                    "link": "/documentation/user-guide/index.html#transfer"
                },
                {
                    "btntype": "link",
                    "className": "btn secondary-btn",
                    "label": "Get Files out of PMP",
                    "link": "/documentation/user-guide/index.html#transfer"
                },
                {
                    "btntype": "link",
                    "className": "btn secondary-btn ",
                    "label": "FAQ",
                    "link": "/documentation/PMP-FAQ.html"
                },
                {
                    "btntype": "dropdown",
                    "className": "btn secondary-btn",
                    "label": "Contact ",
                    "menu": [
                        {
                            "label": "Contact Us",
                            "link": "/contact",
                            "target": "_self"
                        },
                        {
                            "label": "Technical Support",
                            "link": "/technical-support",
                            "target": "_self"
                        }
                    ]
                }];

            const quickLinksDiv = document.getElementById("quick-links");
            const quickLinkBtns = createButtons(quickLinkContent);
            quickLinksDiv.appendChild(quickLinkBtns);

}

function loadCvdTiles() {

const docCardsDiv = document.getElementById("docCards");
    const usageCardsDiv = document.getElementById("usageCards");

    const docCardsContent = {
        // "row_1": {
        //     "cardClassName": "card  dataset-doc-card",
        //     "contentClassName": "card-content",
        //     "icon": "fa fa-search-location",
        //     "header": "Data Exploration",
        //     "content": "<p>Interactively explore all variables in the data and their accompanying documentation.</p>",
        //     "btnContent": [{
        //         "btntype": "link",
        //         "className": "btn primary-btn-doc",
        //         "label": "Explore COVID19 Data",
        //         "link": "/assets/downloads/cvd/search-data-documentation.html"
        //     }]
        // },
        "row_1":
            {
                "cardClassName": "card dataset-doc-card",
                "contentClassName": "card-content",
                "icon": "fa fa-file-invoice",
                "header":"Dictionary & Coding",
                "content": "<p>PDFs describing variable definitions, formats, and coding are in the " +
                    "<code>GWTG/COVID19/documentation</code> folder within the workspace and can also be viewed using the " +
                    "buttons below. The documentation contained in these files is also available " +
                    "using the <a href='variable_documentation_R/covid_Documentation_DTdatatable_example.html' target='_blank'>Explore and Discover website</a> described above.</p>",
                "btnContent": [
                    {
                        "btntype": "link",
                        "className": "btn primary-btn-doc",
                        "label": "Data Dictionary",
                        "link": "/assets/downloads/cvd/aug20ahacovid19cvd_data_dictionary.pdf"
                    },
                    {
                        "btntype": "link",
                        "className": "btn primary-btn-doc",
                        "label": "Coding Manual",
                        "link": "/assets/downloads/cvd/aug20ahacovid19cvd_coding_manual.pdf"
                    }
                ]
            },
        "row_2":
            {
                "cardClassName": "card dataset-doc-card",
                "contentClassName": "card-content",
                "icon": "fa fa-notes-medical",
                "header":"Registry Collection",
                "content": "<p>The <code>covid19_cvd_registry_collection_instructions</code> pdf contains " +
                    "registry data collection criterion, definitions, and protocols for entry and collected measures. " +
                    "This file can is also available in the <code>GWTG/COVID19/documentation</code> workspace folder.</p>",
                "btnContent": [
                    {
                        "btntype": "link",
                        "className": "btn primary-btn-doc",
                        "label": "Collection Instructions",
                        "link": "/assets/downloads/cvd/ahacovid19cvd_registry_collection_instructions.pdf"
                    },
                    {
                        "cardClassName": "card dataset-doc-card",
                        "contentClassName": "card-content",
                        "btntype": "link",
                        "className": "btn primary-btn-doc",
                        "label": "Collection Form",
                        "link": "/assets/downloads/cvd/ahacovid19cvd_coded_collection_form.pdf"
                    }
                ]
            }
    };
    const usageCardsContent = {
        "row_1":
            {
                "cardClassName": "card dataset-doc-card",
                "contentClassName": "card-content",
                "icon": "fa fa-laptop-code",
                "header": "Data Usage",
                "content": "<p>The COVID CVD registry data is available in the <code>GWTG/COVID19/data</code> folder. " +
                    "Examples for how to load and use the data in SAS, R, and Python are available in " +
                    "the <code>GWTG/COVID19/usage_and_examples</code> folder and can also be accessed via the buttons below.</p>",
                "btnContent": [
                    {
                        "btntype": "link",
                        "className": "btn primary-btn-doc",
                        "label": "<img src='https://www.r-project.org/logo/Rlogo.png' alt='drawing' style='width:35px; vertical-align:middle; padding-right:5px;'>  R",
                        "link": "/request/data/cvd/data-usage-r/loggedin",
                        "target": "_self"
                    },
                    {
                        "btntype": "link",
                        "className": "btn primary-btn-doc",
                        "label": "<img src='https://www.python.org/static/opengraph-icon-200x200.png' alt='drawing' style='width:30px; vertical-align:middle; padding-right:5px;'>Python",
                        "link": "/request/data/cvd/data-usage-python/loggedin",
                        "target": "_self"
                    },
                    {
                        "btntype": "link",
                        "className": "btn primary-btn-doc",
                        "label": "<img src='https://www.automateexcel.com/excel/wp-content/uploads/2018/04/icon-shortcuts-sas.png' alt='drawing' style='width:30px; vertical-align:middle; padding-right:5px;'>SAS",
                        "link": "/request/data/cvd/data-usage-sas/loggedin",
                        "target": "_self"
                    }]
            },
        "row_2":
            {
                "cardClassName": "card dataset-doc-card",
                "contentClassName": "card-content",
                "icon": "fa fa-lightbulb",
                "header":"Tips and Guidelines",
                "content": "<p>The Analysis Guide includes common criteria and filters " +
                    "to apply when using the COVID Registry data in analyses. You will also find general guidelines " +
                    "regarding quality checks and missing values. The Manuscript Guide and NDA-DUA contains" +
                    " guidelines and recommendations for data use and authorship. In the workspace, guideline files are " +
                    "available in <code>GWTG/COVID19/analysis_manuscript_guidelines</code>.</p>",
                "btnContent": [
                    {
                        "btntype": "link",
                        "className": "btn primary-btn-doc",
                        "label": "Analysis Guide",
                        "link": "/request/data/cvd/guide/loggedin",
                        "target": "_self"
                    },
                    {
                        "btntype": "link",
                        "className": "btn primary-btn-doc",
                        "label": "Manuscript Guide",
                        "link": "/assets/downloads/cvd/analysis_manuscript_guidelines/AHA_COVID-19_Registry_Author_Reference_Guide.pdf"
                    },
                    {
                        "btntype": "link",
                        "className": "btn primary-btn-doc",
                        "label": "NDA-DUA",
                        "link": "/assets/downloads/cvd/analysis_manuscript_guidelines/AHA_GWTG_COVID-19_PMP_NDA-DUA.pdf"
                    }
                ]
            },
        "row_3":
            {
                "cardClassName": "card dataset-doc-card",
                "contentClassName": "card-content",
                "icon": "fa fa-question-circle ",
                "header": "Need Help?",
                "content":
                    "<p><ul>" +
                    "<li>For PMP questions, check out the FAQ and PMP User Guide</li>" +
                    "<li>For technical problems or installation issues, file a ticket with " +
                    "<a href ='/technical-support'>Technical Support</a></li>" +
                    "<li>For data questions, account questions, and additional PMP or analysis support, please " +
                    "<a href ='/contact'>Contact Us.</li>" +
                    "</ul></p>",
                "btnContent": [
                    {
                        "btntype": "link",
                        "className": "btn primary-btn-doc",
                        "label": "PMP FAQ",
                        "link": "/documentation/PMP-FAQ.html"
                    },
                    {
                        "btntype": "link",
                        "className": "btn primary-btn-doc",
                        "label": "PMP User Guide",
                        "link": "/documentation/user-guide/index.html"
                    }
                ]
            }
    };
    createCards(docCardsContent, docCardsDiv, "row container-fluid")
    createCards(usageCardsContent, usageCardsDiv, "row container-fluid")

}