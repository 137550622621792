function  rgrLinks() {
    const quickLinkContent = [
//        {
//            "btntype": "link",
//            "className": "btn secondary-btn",
//            "label": "Explore RGR Data",
//            "link": "/search/data/rgr/explore-RGR-data/loggedin"
//        },
        {
          "btntype": "link",
          "className": "btn secondary-btn",
          "label": "Explore RGR Data",
          "link": "/rgr-snapshot",
          "target": "_blank"  
        },
        {
            "btntype": "dropdown",
            "className": "btn secondary-btn",
            "label": "RGR Data Usage",
            "menu": [
                {
                    "label": "<img src='https://www.r-project.org/logo/Rlogo.png' alt='drawing' style='width:17%; vertical-align:middle; padding-right:5px; padding-bottom: 5px;'>  R",
                    "link": "/request/data/rgr/data-usage-r/loggedin",
                    "target": "_blank"
                },
                {
                    "label": "<img src='https://www.python.org/static/opengraph-icon-200x200.png' alt='drawing' style='width:20%; vertical-align:middle; padding-right:5px; padding-bottom: 5px;'>Python",
                    "link": "/request/data/rgr/data-usage-python/loggedin",
                    "target": "_blank"
                }
            ]
        },
        {
            "btntype": "link",
            "className": "btn secondary-btn",
            "label": "Upload Files to PMP",
            "link": "/documentation/user-guide/index.html#transfer"
        },
        {
            "btntype": "link",
            "className": "btn secondary-btn",
            "label": "Get Files out of PMP",
            "link": "/documentation/user-guide/index.html#transfer"
        },
        {
            "btntype": "link",
            "className": "btn secondary-btn ",
            "label": "FAQ",
            "link": "/documentation/PMP-FAQ.html"
        },
        {
            "btntype": "dropdown",
            "className": "btn secondary-btn",
            "label": "Contact ",
            "menu": [
                {
                    "label": "Contact Us",
                    "link": "/contact",
                    "target": "_blank"
                },
                {
                    "label": "Technical Support",
                    "link": "/technical-support",
                    "target": "_blank"
                }
            ]
        }];
    const quickLinksDiv = document.getElementById("quick-links");
    const quickLinkBtns = createButtons(quickLinkContent);
    quickLinksDiv.appendChild(quickLinkBtns);
}

function loadRgrTiles() {
const docCardsDiv = document.getElementById("docCards");
    const usageCardsDiv = document.getElementById("usageCards");

    const docCardsContent = {
        // "row_1": {
        //     "cardClassName": "card dataset-doc-card",
        //     "contentClassName": "card-content",
        //     "icon": "fa fa-search-location",
        //     "header": "Data  Exploration",
        //     "content": "Interactively explore all variables in the data and their accompanying documentation.",
        //     "btnContent": [{
        //         "btntype": "link",
        //         "className": "btn primary-btn",
        //         "label": "Explore Resus Data",
        //         "link": "search-data-documentation.html"
        //     }]
        // },
        "row_2":
            {
                "cardClassName": "card dataset-doc-card",
                "contentClassName": "card-content",
                "icon": "fa fa-file-invoice",
                "header":"Dictionaries and Coding",
                "content": "<p>PDFs describing variable definitions, formats, and coding are in the " +
                    "<code>Research_Goes_Red/Documentation</code> folder within the workspace and can also be viewed using the " +
                    "buttons below.</p>",
                    // "The documentation contained in these files is also available " +
                    // "using the <a href='variable_documentation_R/covid_Documentation_DTdatatable_example.html' target='_blank'>Explore and Discover website</a> described above.",
                "btnContent": [
                    {
                        "btntype": "link",
                        "className": "btn primary-btn-doc",
                        "label": "Data Dictionary",
                        "link": "https://docs.google.com/spreadsheets/d/1eE3x5ER964s-HnRagb65YJX_OmxeHndu/edit#gid=1692759649"
                    }
                    // {
                    //     "btntype": "link",
                    //     "className": "btn primary-btn",
                    //     "label": "Coding Manual",
                    //     "link": "Resus_files/documentation/V12_gwtgresus_cpa_adult_coding.rtf"
                    // }
                ]
            },
        "row_3":
            {
                "cardClassName": "card dataset-doc-card",
                "contentClassName": "card-content",
                "icon": "fa fa-notes-medical",
                "header":"Data Collection Survey Forms",
                "content": "<p>The <code>Research_Goes_Red/Documentation/Forms</code> folder contains " +
                    "data collection forms used in the surveys. " +
                    "These forms can also be found below.</p>",
                "btnContent": [
                    {
                        "btntype": "link",
                        "className": "btn primary-btn-doc",
                        "label": "Registry Enrollment",
                        "link": "/assets/downloads/rgr/documentation/forms/Registry Enrollment (REGENROLLC) v12.pdf"
                    },
                    {
                        "btntype": "link",
                        "className": "btn primary-btn-doc",
                        "label": "Research Goes Red Survey",
                        "link": "/assets/downloads/rgr/documentation/forms/Reserach Goes Red Survey (RGR) v3.pdf"
                    },
                    {
                        // "cardClassName": "card dataset-doc-card",
                        // "contentClassName": "card-content",
                        "btntype": "link",
                        "className": "btn primary-btn-doc",
                        "label": "PANAS Survey",
                        "link": "/assets/downloads/rgr/documentation/forms/Feelings and Emotions (PANAS) v2.pdf"
                    },
                    {
                        // "cardClassName": "card dataset-doc-card",
                        // "contentClassName": "card-content",
                        "btntype": "link",
                        "className": "btn primary-btn-doc",
                        "label": "Registry Profile Completion Survey",
                        "link": "/assets/downloads/rgr/documentation/forms/Project Baseline Registry Profile Completion (PROFC) v11.pdf"
                    },
                    {
                        // "cardClassName": "card dataset-doc-card",
                        // "contentClassName": "card-content",
                        "btntype": "link",
                        "className": "btn primary-btn-doc",
                        "label": "RGR COVID-19 Survey",
                        "link": "/assets/downloads/rgr/documentation/forms/RGR COVID-19 Survey (RGRC19) v1.pdf"
                    },
                    {
                        // "cardClassName": "card dataset-doc-card",
                        // "contentClassName": "card-content",
                        "btntype": "link",
                        "className": "btn primary-btn-doc",
                        "label": "RGR Weight Study Eligibility Survey",
                        "link": "/assets/downloads/rgr/documentation/forms/RGR Weight Study Eligibility Survey (RGRWSES) v3.pdf"
                    },
                    {
                        // "cardClassName": "card dataset-doc-card",
                        // "contentClassName": "card-content",
                        "btntype": "link",
                        "className": "btn primary-btn-doc",
                        "label": "COVID-19, Women, & Worry Survey",
                        "link": "/assets/downloads/rgr/documentation/forms/COVID-19, Women, and Worry (COVIDWW) v3.pdf"
                    },
                    {
                        // "cardClassName": "card dataset-doc-card",
                        // "contentClassName": "card-content",
                        "btntype": "link",
                        "className": "btn primary-btn-doc",
                        "label": "Barriers and Motivations to Research Survey",
                        "link": "/assets/downloads/rgr/documentation/forms/Barriers and Motivators to Resarch (BMR) v1.pdf"
                    },
                    {
                        // "cardClassName": "card dataset-doc-card",
                        // "contentClassName": "card-content",
                        "btntype": "link",
                        "className": "btn primary-btn-doc",
                        "label": "Reproductive Health Survey",
                        "link": "/assets/downloads/rgr/documentation/forms/Reproductive Health Survey (RHS) v2.pdf"
                    },
                    {
                        // "cardClassName": "card dataset-doc-card",
                        // "contentClassName": "card-content",
                        "btntype": "link",
                        "className": "btn primary-btn-doc",
                        "label": "Healthcare Experiences and Perceptions Survey",
                        "link": "/assets/downloads/rgr/documentation/forms/Healthcare Experiences and Perceptiosn (HEP) v1.pdf"
                    }
                ]
            }
    };
    const usageCardsContent = {
        "row_1":
            {
                "cardClassName": "card dataset-doc-card",
                "contentClassName": "card-content",
                "icon": "fa fa-laptop-code",
                "header": "Data Usage",
                "content": "<p>The Research Goes Red data is available in the <code>Research_Goes_Red/Data</code> folder. " +
                    "Examples for how to load and use the data in R and Python are available in " +
                    "the <code>Research_Goes_Red/Tutorials</code> folder and can also be accessed via the buttons below.</p>",
                "btnContent": [
                    {
                        "btntype": "link",
                        "className": "btn primary-btn-doc",
                        "label": "<img src='https://www.r-project.org/logo/Rlogo.png' alt='drawing' style='width:35px; vertical-align:middle; padding-right:5px;'>  R",
                        "link": "/request/data/rgr/data-usage-r/loggedin"
                    },
                    {
                        "btntype": "link",
                        "className": "btn primary-btn-doc",
                        "label": "<img src='https://www.python.org/static/opengraph-icon-200x200.png' alt='drawing' style='width:30px; vertical-align:middle; padding-right:5px;'>Python",
                        "link": "/request/data/rgr/data-usage-python/loggedin"
                    }]
                    // {
                    //     "btntype": "link",
                    //     "className": "btn primary-btn",
                    //     "label": "<img src='https://www.automateexcel.com/excel/wp-content/uploads/2018/04/icon-shortcuts-sas.png' alt='drawing' style='width:30px; vertical-align:middle; padding-right:5px;'>SAS",
                    //     "link": "COVID19_files/usage_and_examples/SAS/SAS_covid19_cvd_data_usage.html"
                    // }]
            },
        "row_2":
            {
                "cardClassName": "card dataset-doc-card",
                "contentClassName": "card-content",
                "icon": "fa fa-lightbulb",
                "header":"Tips and Guidelines",
                "content": 
                    "<p><ul>" +                
                    "The Analysis Guide includes common criteria and filters " +
                    "to apply when using the RGR data in analyses. You will also find general guidelines " +
                    "regarding quality checks and missing values. The Manuscript Guide and NDA-DUA contains" +
                    " guidelines and recommendations for data use and authorship." + 
                    "</ul></p>",
                "btnContent": [
                    {
                        "btntype": "link",
                        "className": "btn primary-btn-doc",
                        "label": "Manuscript Proposal Form",
                        "link": "/assets/downloads/rgr/documentation/forms/researchgoesred_manuscriptproposalform_Apr2022.docx"
                    },
                    {
                        "btntype": "link",
                        "className": "btn primary-btn-doc",
                        "label": "Publication Policy",
                        "link": "/assets/downloads/rgr/documentation/forms/RGR Manuscript Proposal Development and Publication Policies.pdf"
                    },
                    {
                        "btntype": "link",
                        "className": "btn primary-btn-doc",
                        "label": "Publication Procedure Flowchart",
                        "link": "/assets/downloads/rgr/documentation/forms/RGRPublicationFlowchart.pdf"
                    },
                ]
            },
        "row_3":
            {
                "cardClassName": "card dataset-doc-card",
                "contentClassName": "card-content",
                "icon": "fa fa-question-circle ",
                "header": "Need Help?",
                "content":
                    "<p><ul>" +
                    "<li>For PMP questions, check out the FAQ and PMP User Guide</li>" +
                    "<li>For technical problems or installation issues, file a ticket with " +
                    "<a href ='https://precision.heart.org/technical-support'>Technical Support</a></li>" +
                    "<li>For data questions, account questions, and additional PMP or analysis support, please " +
                    "<a href ='https://precision.heart.org/contact'>Contact Us.</li>" +
                    "</ul></p>",
                "btnContent": [
                    {
                        "btntype": "link",
                        "className": "btn primary-btn-doc",
                        "label": "PMP FAQ",
                        "link": "/documentation/PMP-FAQ.html"
                    },
                    {
                        "btntype": "link",
                        "className": "btn primary-btn-doc",
                        "label": "PMP User Guide",
                        "link": "/documentation/user-guide/index.html"
                    }
                ]
            }
    };
    createCards(docCardsContent, docCardsDiv, "row container-fluid")
    createCards(usageCardsContent, usageCardsDiv, "row container-fluid")

}


// function loadRgrDataTiles() {
// // parse the data from the csv file
//     const data_parse = Papa.parse('/assets/downloads/rgr/data/rgr_test_var_stats.csv', {
//         // after parsing is complete, call the build_data_dict() function
//         complete: function(results) {
//             console.log('Parse complete')
//             build_data_dict(results.data);
//         },
//         download: true,
//         header: true,
//         error: (error, file) => {
//             console.log('Error while parsing:', error, file);
//         },
//     });

    // function to build the data dict, plot dict, and collapsibles
//     const build_data_dict = function (data) {
//         console.log('raw data: ', data);
//         console.log("Building Data Dict");
//         // generate a list of all variable names
//         var var_names = [];
//         for (let i = 0; i < data.length; i++){
//         var_names[i] = data[i]["var"];
//         };

//         // generate array of unique variable names
//         var uniq_var_names = Array.from(new Set(var_names));
//         // console.log('uniq_var_names: ', uniq_var_names);

//         // init both dicts
//         var data_dict = {};
//         var plot_dict = {};
//         for (let i = 0; i < uniq_var_names.length; i++) {
//         data_dict[uniq_var_names[i]] = {}
//         };

//         // generate a dict mapping the var_name to the statistics
//         for (let i = 0; i < data.length; i++){
//         let var_name = data[i]["var"];
//         // append the stats to the variable name in the data_dict
//         data_dict[var_name][data[i]["measure"]] = data[i]["value"];
//         };
//         // console.log(data_dict);

//         // sanity check: get the length of each dict corresponding to the variable
//         console.log(Object.keys(data_dict).length);
//         let dict_len = {};
//         for (let key in data_dict) {
//             if (typeof dict_len[Object.keys(data_dict[key]).length] === 'number') {
//                 dict_len[Object.keys(data_dict[key]).length] += 1;
//             } else {
//                 dict_len[Object.keys(data_dict[key]).length] = 1;
//             };
//         };
//         console.log('dict_len: ', dict_len);
//         console.log("Data Dict Finished");
//         console.log('Data Dict: ', data_dict);

//         // build the dict containing plot data
//         console.log("Building Plot Dict")
//         // Need to first determine the type of plot to be generated. (maybe by checking the number of keys in the dict?)
//         // Then need to pass the in the correct format for the data (maybe return the data, with a string indicating the type of plot)
//         // Need to determine whether to process the string as a date or as a number (regex check for any '/', or maybe if the vairable ends in 'DT')
//         // In case of bar plot, need to transform the dict into a list of lists (also need to regex the count and separate it from the percentage)
//         // In case of boxplot, need to transform the dict keys into AnyChart key syntax
//         for (let i = 0; i < uniq_var_names.length; i++){
//             let var_name = uniq_var_names[i]
//             plot_dict[var_name] = {};

//             // identify plot type
//             let plot_type = "N/A"
//             if (Object.keys(data_dict[var_name]).length === 12) {
//                 plot_type = "box";
//             } else {
//                 plot_type = "bar";
//             };
//             plot_dict[var_name]["plot_type"] = plot_type;

//             // init the plot_data object
//             var plot_data = [];

//             // in case of bar plot, transform the dict into a list of lists (also use regex to separate the counts and the percentage)
//             if (plot_type === "bar") {
//                 for (const [key, value] of Object.entries(data_dict[var_name])) {
//                     const regex_count = /(.+ )/g;
//                     const value_match = value.match(regex_count);
//                     plot_data.push([key, parseFloat(value_match[0])]);
//                 };
//             }
//             // in case of box plot, transform the dict keys into AnyChart key syntax
//             else if (plot_type === "box") {
//                 // check whether the data is a date or a float
//                 var data_type = "N/A";
//                 const regex_date = /(..)$/g;
//                 const var_match = var_name.match(regex_date);
//                 if (var_match[0] === "DT") {
//                     data_type = "date";
//                 } else {
//                     data_type = "number";
//                 };

//                 // populate the entry
//                 if (data_type === "number") {
//                     plot_data[0] = {};
//                     plot_data[0]["x"] = var_name;
//                     plot_data[0]["q1"] = parseFloat(data_dict[var_name]["Q1"]);
//                     plot_data[0]["q3"] = parseFloat(data_dict[var_name]["Q3"]);
//                     plot_data[0]["median"] = parseFloat(data_dict[var_name]["median"]);
//                     plot_data[0]["low"] = parseFloat(data_dict[var_name]["min"]);
//                     plot_data[0]["high"] = parseFloat(data_dict[var_name]["max"]);
//                 } else if (data_type === "date") {
//                     plot_data[0] = {};
//                     plot_data[0]["x"] = var_name;
//                     plot_data[0]["q1"] = Date.parse(data_dict[var_name]["Q1"]);
//                     plot_data[0]["q3"] = Date.parse(data_dict[var_name]["Q3"]);
//                     plot_data[0]["median"] = Date.parse(data_dict[var_name]["median"]);
//                     plot_data[0]["low"] = Date.parse(data_dict[var_name]["min"]);
//                     plot_data[0]["high"] = Date.parse(data_dict[var_name]["max"]);
//                 };
//             };
//             // return the data
//             plot_dict[var_name]["plot_data"] = plot_data;
//         };
//         console.log("Plot Dict: ", plot_dict);

//         // slice the array for testing purposes
//         // uniq_var_names_temp_1 = uniq_var_names.slice(0, 5);
//         // uniq_var_names_temp_2 = uniq_var_names.slice(Math.max(uniq_var_names.length - 50, 0), -40);
//         // uniq_var_names = uniq_var_names_temp_1.concat(uniq_var_names_temp_2);
//         console.log("uniq_var_names: ", uniq_var_names);

//         // build out the collapsibles for each variable
//         const main  = document.getElementById("main");
//         uniq_var_names.forEach(function (x) {
//             // console.log('x: ', x);
//             // console.log('data_dict[x]: ', data_dict[x]);
//             // console.log('plot_dict[x]: ', plot_dict[x]);
//             const categoryBtn = document.createElement("button");
//             const anchor = document.createElement("a");
//             const headerContent = document.createElement("span");
//             const header = document.createElement("h2");
//             const img = document.createElement("img");
//             const content_id = x;
//             const plusIcon = document.createElement("i");

//             // create a div for the variable content
//             const varContent = document.createElement("div");
//             // create a div for the documentation links
//             const docContent = document.createElement("div");
//             // create a div for the data table
//             const dataContent = document.createElement("div");
//             // create a div for the plot
//             const plotContent = document.createElement("div");

//             img.alt = "drawing";
//             // img.src = "https://www.heart.org/-/media/foundation/metadata/aha_logo.png";
//             categoryBtn.className = "collapsible";
//             headerContent.className = "torch-header section-header grey-header"
//             anchor.id = "var-" + content_id;
//             anchor.className = "var-anchor";
//             plusIcon.className = "fas fa-plus";
//             plusIcon.id = content_id + "-plus";
//             categoryBtn.onclick = function() {toggle(content_id, plusIcon.id)};

//             varContent.className = "collapsible-content";
//             varContent.id = content_id;
//             varContent.style.display = "block";

//             // init the documentation collapsible
//             docContent.className = "collapsible-content";
//             docContent.id = content_id + "-doc";
//             docContent.style.display = "block";
//             //init the table collapsible
//             dataContent.className = "collapsible-content";
//             dataContent.id = content_id + "-data";
//             dataContent.style.display = "block";
//             // init the plot collapsible
//             plotContent.className = "collapsible-content";
//             plotContent.id = content_id + "-plot";
//             plotContent.style.display = "block";

//             // content in the header
//             main.appendChild(categoryBtn);
//             categoryBtn.appendChild(anchor);
//             categoryBtn.appendChild(headerContent);
//             headerContent.appendChild(header);
//             header.innerHTML = x;
//             // header.append(img);
//             header.append(plusIcon);

//             main.appendChild(varContent);
//             // content in the documentation
//             varContent.appendChild(docContent);
//             const docContainer = document.createElement("div");
//             const docBtn = document.createElement("button");
//             const docText = document.createElement("span");
//             const docName = document.createElement("a");
//             const docCaret = document.createElement("i");
//             const docList = document.createElement("ul");
//             const docList_1 = document.createElement("li");
//             const docList_2 = document.createElement("li");
//             const docLink_1 = document.createElement("a");
//             const docLink_2 = document.createElement("a");

//             docList.id = content_id + '-answer' + '-doc';
//             docList.style.display = "none";
//             docLink_1.href = "/assets/downloads/cvd/ahacovid19cvd_coded_collection_form.pdf";
//             docLink_1.target = "_blank";
//             docLink_1.append(x + " in Collection Report Form (Placeholder)");
//             docLink_2.href = "/assets/downloads/cvd/ahacovid19cvd_registry_collection_instructions.pdf";
//             docLink_2.target = "_blank";
//             docLink_2.append(x + " in Registry Collection Instructions (Placeholder)");

//             docCaret.className = "fa fa-caret-right";
//             docCaret.id = content_id + '-caret' + '-doc';
//             docBtn.className = "collapsible faq-question";
//             docBtn.onclick = function() {toggle(docList.id, docCaret.id)};
//             docContainer.className = 'faq-container';

//             docName.appendChild(docCaret);
//             docText.append("Documentation");
//             docName.appendChild(docText);
//             docBtn.appendChild(docName);
//             docContainer.appendChild(docBtn);
//             docList_1.appendChild(docLink_1);
//             docList_2.appendChild(docLink_2);
//             docList.appendChild(docList_1);
//             docList.appendChild(docList_2);
//             docContainer.appendChild(docList);
//             docContent.appendChild(docContainer);

//             // content in the data table
//             varContent.appendChild(dataContent);
//             const dataContainer = document.createElement("div");
//             const dataBtn = document.createElement("button");
//             const dataText = document.createElement("span");
//             const dataName = document.createElement("a");
//             const dataCaret = document.createElement("i");
//             const dataDisplay = document.createElement("div");
//             const dataTable = document.createElement("div");
//             const dataTableContent = document.createElement("table");
//             const dataTableHead = document.createElement("thead");
//             const dataTableHeader = document.createElement("tr");
//             const dataTableHeader_1 = document.createElement("th");
//             const dataTableHeader_2 = document.createElement("th");
//             const dataTableContentBody = document.createElement("div");
//             const dataTableContentBodyData = document.createElement("table");
//             const dataTableContentBodyTBody = document.createElement("tbody");
//             const dataTableContentBodyTr = document.createElement("tr");
//             const dataTableContentBodyTd = document.createElement("td");

//             dataDisplay.id = content_id + '-answer' + '-table';
//             dataDisplay.className = "content faq-answer";
//             dataDisplay.style.display = "none";
//             // dataDisplay.style.height = "400px";
//             // dataTable.style.height = "400px";
//             // dataTableContentBody.style.height = "400px";

//             dataCaret.className = "fa fa-caret-right";
//             dataCaret.id = content_id + '-caret' + '-table';
//             dataBtn.className = "collapsible faq-question";
//             dataBtn.onclick = function() {toggle(dataDisplay.id, dataCaret.id)};
//             dataContainer.className = "faq-container";

//             dataName.appendChild(dataCaret);
//             dataText.append("Summary Statistics");
//             dataName.appendChild(dataText);
//             dataBtn.appendChild(dataName);
//             dataContainer.appendChild(dataBtn);
//             dataContainer.appendChild(dataDisplay);
//             dataContent.appendChild(dataContainer);

//             dataTable.className = "clusterize";
//             dataTableHeader_1.append("Key");
//             dataTableHeader_2.append("Value");
//             dataTableContentBody.id = content_id + '-scrollArea';
//             dataTableContentBody.className = "clusterize-scroll";
//             dataTableContentBodyTBody.id = content_id + '-contentArea';
//             dataTableContentBodyTBody.className = "clusterize-content";
//             dataTableContentBodyTr.className = "clusterize-no-data";
//             dataTableContentBodyTd.append("Loading Data...");

//             dataTableContentBodyTr.appendChild(dataTableContentBodyTd);
//             dataTableContentBodyTBody.appendChild(dataTableContentBodyTr);
//             dataTableContentBodyData.appendChild(dataTableContentBodyTBody);
//             dataTableContentBody.appendChild(dataTableContentBodyData);

//             dataTableHeader.appendChild(dataTableHeader_1);
//             dataTableHeader.appendChild(dataTableHeader_2);
//             dataTableHead.appendChild(dataTableHeader)
//             dataTableContent.appendChild(dataTableHeader);

//             dataTable.appendChild(dataTableContent);
//             dataTable.appendChild(dataTableContentBody);
//             dataDisplay.appendChild(dataTable);

//             // testing clusterize
//             var dataElements = Object.entries(data_dict[x]);
//             var clusterize = new Clusterize({
//                                     rows: dataElements.map(function(row) {
//                                                     return "<tr>" +
//                                                         row.map(function(col) {
//                                                         return '<td>' + col + '</td>';
//                                                         }).join(" ") +
//                                                     "</tr>"
//                                                     }),
//                                     scrollId: content_id + '-scrollArea',
//                                     contentId: content_id + '-contentArea'
//                                 });

//             // content in the plot
//             varContent.appendChild(plotContent);
//             const plotContainer = document.createElement("div");
//             const plotBtn = document.createElement("button");
//             const plotText = document.createElement("span");
//             const plotName = document.createElement("a");
//             const plotCaret = document.createElement("i");
//             const plotVis = document.createElement("div");

//             plotVis.id = content_id + '-answer' + '-plot';
//             plotVis.className = "content faq-answer";
//             plotVis.style.display = "none";
//             // plotVis.style.width = "700px";
//             plotVis.style.height = "400px";

//             plotCaret.className = "fa fa-caret-right";
//             plotCaret.id = content_id + '-caret' + '-plot';
//             plotBtn.className = "collapsible faq-question";
//             plotBtn.onclick = function() {toggle(plotVis.id, plotCaret.id)};
//             plotContainer.className = "faq-container"

//             plotName.appendChild(plotCaret);
//             plotText.append("Plot");
//             plotName.appendChild(plotText);
//             plotBtn.appendChild(plotName);
//             plotContainer.appendChild(plotBtn);
//             plotContainer.appendChild(plotVis);
//             plotContent.appendChild(plotContainer);

//             // draw the chart under div=plotVis.id
//             var chart = anychart.vertical();
//             if (plot_dict[x]["plot_type"] == "box") {
//                 var series = chart.box(plot_dict[x]["plot_data"]);
//             }
//             else if (plot_dict[x]["plot_type"] == "bar") {
//                 var series = chart.bar(plot_dict[x]["plot_data"]);
//             }
//             chart.title('Data Distribution');
//             chart.container(plotVis.id);
//             chart.draw();
//         })
//     };
// }

function  rgrDataLinks() {
    const categoryDropdownContent = [
                    {
                        "btntype": "dropdown",
                        "className": "btn secondary-btn faq-nav-dropdown",
                        "label": "Variable Categories",
                        "menu": [
                            {
                                "label": "Admin",
                                "link": "#var-admin",
                                "target": "_self",
                            },
                            // {
                            //     "label": "Admission",
                            //     "link": "#var-admission",
                            //     "target": "_self",
                            // },
                            // {
                            //     "label": "AdmLabs",
                            //     "link": "#var-admlabs",
                            //     "target": "_self",
                            // },
                            // {
                            //     "label": "Medications",
                            //     "link": "#var-medications",
                            //     "target": "_self",
                            // },
                            {
                                "label": "Identifier",
                                "link": "#var-identifier",
                                "target": "_self",
                            },
                            // {
                            //     "label": "Hospitalization",
                            //     "link": "#var-hospitalization",
                            //     "target": "_self",
                            // },
                            {
                                "label": "Demographics",
                                "link": "#var-demographics",
                                "target": "_self",
                            },
                            {
                                "label": "Ungrouped",
                                "link": "#var-ungrouped",
                                "target": "_self",
                            }
                        ]
                    }];
                const cateogryDropdown = document.getElementById("faq-category-dropdown");
                const cateogryDropdownBtn = createButtons(categoryDropdownContent);
                cateogryDropdown.appendChild(cateogryDropdownBtn);
    
                

// }
// function exploreRGRdata() {
  
//             $(function() {
//                 Papa.parse("/assets/downloads/gcrff/Proposal-data2.10.csv", {
//                     download: true,
//                     complete: function(grant_data) {

//                         $(document).ready(function () {
//                             debugger
//                             grant_data.data.pop()
//                             grant_data.data.forEach(function(row) {
//                                 // subset dates to year only
//                                 // row[2] = row[2].substring(row[2].length - 4, row[2].length)
//                                 // row[3] = row[3].substring(row[3].length - 4, row[3].length)
//                                 })
                                

//                             })
//                         }
//                     })
//                 }
//             }
                
                }